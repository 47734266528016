'use client'

import {
  appRouterSsrErrorHandler,
  type AppRouterErrorProps,
} from '@highlight-run/next/ssr'

import { CenteredLayout } from '@mntn-dev/ui-components'

import { ErrorPage } from '#components/error/error-page.tsx'

export default appRouterSsrErrorHandler(({ error }: AppRouterErrorProps) => {
  console.error(error)

  // currently this error object does not return an error code, so for now we assume it is always 500.
  return (
    <CenteredLayout>
      <ErrorPage code={500} />
    </CenteredLayout>
  )
})
